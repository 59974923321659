@import '../theme/mixins';

$brand: 'epi';
$brandAssets: '/assets/#{$brand}';

@font-face {
	font-family: AppFont;
	src: url('../assets/fonts/Montserrat-Regular.ttf');
}
@font-face {
	font-family: AppFontBold;
	src: url('../assets/fonts/Montserrat-Bold.ttf');
}
@font-face {
	font-family: AppFontMedium;
	src: url('../assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
	font-family: AppFontSemiBold;
	src: url('../assets/fonts/Montserrat-SemiBold.ttf');
}

:root {
	--brand: $brand;
	--ion-color-primary: #F8C5D2;
	--ion-color-primary-rgb: 248,197,210;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #daadb9;
	--ion-color-primary-tint: #f9cbd7;

	--ion-color-secondary: #f9acca;
	--ion-color-secondary-rgb: 249, 172, 202;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #db97b2;
	--ion-color-secondary-tint: #fab4cf;

	--ion-color-tertiary: #808080;
	--ion-color-tertiary-rgb: 128, 128, 128;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #717171;
	--ion-color-tertiary-tint: #8d8d8d;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-dark: #000;
	--ion-color-dark-rgb: 102, 96, 90;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #5a544f;
	--ion-color-dark-tint: #75706b;

	--ion-color-medium: #808080;
	--ion-color-medium-rgb: 128, 128, 128;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #717171;
	--ion-color-medium-tint: #8d8d8d;

	--ion-color-light: #b0b0b0;
	--ion-color-light-rgb: 176, 176, 176;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #9b9b9b;
	--ion-color-light-tint: #b8b8b8;

	--ion-font-family: AppFont, sans-serif;
	--ion-margin: 12px;
	--ion-padding: 12px;

	--default-background: #f6f0eb;
}

body {
	font-family: AppFont, sans-serif;
	color: var(--ion-color-black);
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: AppFontBold, Helvetica, sans-serif;
	color: var(--color-h);
	margin: 0;
}
p,
span {
	font-family: AppFont, sans-serif;
	color: var(--color-p-span);
	margin: 0;
}

app-email-action,
// app-sign-in,
// app-sign-in-order,
// app-sign-up,
app-maintenance-page,

app-checkout-modal,
app-payment-modal,
app-menu-modal {
	background: var(--background-color);
	background-size: cover;
	ion-header {
		background: transparent;
	}
	ion-content {
		background: url(../assets/the-cup/background.png) no-repeat center center/ 100% 100% !important;
		--background: url(../assets/the-cup/background.png) no-repeat center center/ 100% 100% !important;
	}

	h3 {
		&:first-child {
			margin-top: 0px;
		}
	}
}

app-payment-success {
	background: url(../assets/the-cup/background.png) no-repeat center center/ 100% 100% !important;
	--background: url(../assets/the-cup/background.png) no-repeat center center/ 100% 100% !important;

	@include mobile {
		background: url('../assets/the-cup/background.png') no-repeat top center/ 100% 40% !important;
		--background: transparent !important;
	}

	ion-content {
		--background: transparent !important;
	}
}

app-payment-modal {
	ion-content {
		background: #fff !important;
		--background: #fff !important;
	}
}

app-home,
app-menu {
	background: black url(../assets/the-cup/desktop-bg.png) no-repeat center center / 100% 100%;

	@include desktop {
		background: black url(../assets/the-cup/desktop-bg.png) no-repeat center center / 100% 100%;
	}

	background-size: cover;

	ion-header {
		background: transparent;
	}
	ion-content {
		background: transparent;
		background-size: cover;
		--background: transparent;
	}
	ion-footer {
		--background: transparent;
		background: transparent;
	}

	h3 {
		&:first-child {
			margin-top: 0px;
		}
		text-transform: uppercase;
		margin: 0;
	}
}
.container_allergens_info {
	p {
		color: #1a1a18;
	}
}

app-menu {
	background: black url(../assets/the-cup/background.png) no-repeat center center / 100% 100% !important;
	background-size: cover;
	@include desktop {
		background: black url(../assets/the-cup/background.png) no-repeat center center / 100% 100% !important;
		background-size: cover;
	}
}
