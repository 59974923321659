
@import "../theme/mixins";



:root {
  --brand: 'selforder';

  --ion-color-gray-light: #F5F5F5;
  --ion-color-gray-dark: #D4D4D4;
  --ion-color-gray-darkest: #1F1B1C;

  --ion-color-black:  #000000;
  --ion-color-subtitle: #B0B0B0;
  --ion-color-gold: #A38A5F;

  //main variables
  --background-color: #fff;
  --background-toolbar-color: #fff;
  --color-h: var(--ion-color-black);
  --color-p-span: var(--ion-color-subtitle);
  --buttons-border-radius: 28px;
  --buttons-font-family: AppFontBold, sans-serif;
  --buttons-font-size: 18px;
  --buttons-text-transform: uppercase;
  --buttons-text-color: white;
  --account-toolbar-background: var(--background-color);
  --text-color: #2C2C31;
  --radio-color: #22222233;
  //home-page
  --home-page-card-backround: white;
  --home-page-language-switcher-color-selected: #2C2C31;
  --home-page-info-font-size: 18px;
  --home-page-card-borde-radius: 0px;
  --home-page-card-box-shadow: none;


    //item-view
    --item-view-background-color: #fff;
    --item-view-box-shadow: 0px 4px 25px 0px #0000001A;
    --item-view-border: 0.5px solid rgba(235, 228, 222, 0.5);

    --item-view-title-font-size: 14px;
    --item-view-title-line-height: 17px;
    --item-view-title-line-height: 17px;
    --item-view-title-line-height: 17px;
    --item-view-title-line-height: 17px;

    --item-view-info-font-size: 12px;
    --item-view-info-line-height: 13px;
    //item-view
  //menu-page

  //modal-info
    --modal-info-title-font-size: 28px;
    --modal-info-title-line-height: 33px;

    --modal-info-desc-font-size: 14px;
    --modal-info-desc-line-height: 18px;
    
    --modal-info-button-font-size: 16px;
    --modal-info-button-text-color: #fff;

    --modal-info-border-color: #22222233;

  //modal-info

  //order-page
    //order-toolbar
      --order-toolbar-color: var(--ion-color-primary);
      --order-toolbar-title-font-size: 14px;
      --order-toolbar-title-line-height: 17px;
    //order-toolbar

    //order-list
      --order-list-title-font-size: 16px;
      --order-list-title-line-height: 19px;

      --order-list-option-name-font-size: 12px;
      --order-list-option-name-line-height: 13px;
      --order-list-option-name-color: #222222;
      --order-list-option-subtitle-color: #222222BF;

      --order-list-article-price-font-size: 14px;
      --order-list-oarticle-price-line-height: 20px;

      --order-list-order-item-border: var(--item-view-border);
      --order-list-border: #222222;
    //order-list

    //promo-code
    --promo-code-border: var(--item-view-border);
    box-shadow: var(--item-view-box-shadow);

    //promo-code

    --order-page-add-text-font-size: 12px;
    --order-page-add-text-line-height: 14px;
    --order-page-add-text-boroder: 2px solid #E53238;
    --order-page-add-text-border-radius: 50px; 

    --order-page-footer-btn-text-font-size: 16px;  
    --order-page-footer-btn-text-line-height: 19px;  
  //order-page
  
--menu-modal-name-color: var(--ion-color-primary);



  --quantity-selector-border-width: 1px;
  --quantity-selector-border-color: #22222233;
  --quantity-selector-height: 30px;
  --quantity-selector-background: #fff;
  --quantity-selector-text-color: var(--ion-color-primary);
  --home-preorder-type-button-background: var(--ion-color-primary);
  --preorder-type-chip-border-color: white;
  
  --payment-modal-item-background: white;
  --payment-modal-checkbox-color: black;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-black);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

